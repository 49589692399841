export const templesData = [
  {
    country: "Austria",
    city: "Vienna",
    name: "Shri Guru Ravidass Temple, Vienna, Austria",
    address: "Pelzgasse 17, Saal 1, Vienna, Austria, 1150",
    telephone: [""],
    links: []
  },
  {
    country: "Australia",
    city: "Melbourne",
    name: "Shri Guru Ravidass Sabha Aust Inc",
    address:
      "24 Malcolm Place, Campbellfield Victoria, 3061, Melbourne, Australia",
    telephone: [""],
    links: [
      {
        type: "email",
        href: ""
      },
      {
        type: "facebook",
        href: ""
      },
      {
        type: "instagram",
        href: "instagram"
      },
      {
        type: "youtube",
        href: "href"
      },
      {
        type: "website",
        href: "href"
      }
    ]
  },
  {
    country: "Australia",
    city: "Sydney",
    name: "Shri Guru Ravidass Sabha Sydney Inc",
    address: "PO Box 469, Matraville, New South Wales 2036, Sydney, Australia",
    telephone: [""],
    links: []
  },
  {
    country: "Canada",
    city: "Toronto",
    name: "Gurudwara Ravidass Sikh Temple",
    address: "2284 Queensway Dr Burlington, ON L7R 3T2",
    telephone: [""],
    links: []
  },
  {
    country: "Canada",
    city: "Calgary",
    name: "Guru Ravidass Community Centre",
    address: "1835 40 St SE, Calgary, AB T2B 1B5",
    telephone: [""],
    links: []
  },
  {
    country: "Canada",
    city: "Vancouver",
    name: "Gurudwara Shri Guru Ravidass Sabha",
    address: "7271 Gilley Ave, Burnaby, BC V5J 4W9",
    telephone: [""],
    links: []
  },
  {
    country: "Canada",
    city: "Toronto",
    name: "Shri Guru Ravidass Temple",
    address: "86 Newport Street, Brampton, ON L6S 4S7",
    telephone: [""],
    links: []
  },
  {
    country: "Canada",
    city: "Toronto",
    name: "Shri Guru Ravidass Temple",
    address: "6795 Steeles Ave W, Etobicoke, ON M9V 4R9",
    telephone: [""],
    links: []
  },
  {
    country: "Canada",
    city: "Montreal",
    name: "Shri Guru Ravidass Temple",
    address: "8205 Rue Durocher, Montréal, QC H3N 2A8",
    telephone: [""],
    links: []
  },
  {
    country: "Fiji",
    city: "Nasinu Suva",
    name: "Gurudwara Ravidass",
    address: "No address",
    telephone: [""],
    links: []
  },
  {
    country: "France",
    city: "Paris",
    name: "Gurudwara Shri Guru Ravidass Sabha",
    address: "97 Rue Anatole La Cournehve, Paris France",
    telephone: [""],
    links: []
  },
  {
    country: "Germany",
    city: "Oberurusel",
    name: "Gurughar Shri Guru Ravidass Ji",
    address: "Tabaksmuvehlenweg -30 , 61440 Oberurusel Germany",
    telephone: [""],
    links: []
  },
  {
    country: "Greece",
    city: "Koropi",
    name: "Shri Guru Ravidass Sabha Koropi",
    address: "Frati 26 koropi Attiki Greece 19400",
    telephone: [""],
    links: []
  },
  {
    country: "Holland",
    city: "Amsterdam",
    name: "Stg Shri Guru Ravidass Sabha Nederland",
    address: "Brink 1 - A, 1097 TT Amsterdam, Holland",
    telephone: [""],
    links: []
  },
  {
    country: "Holland",
    city: "The Hauge",
    name: "Shri Guru Ravidass Temple",
    address: "No address",
    telephone: [""],
    links: []
  },
  {
    country: "Italy",
    city: "Vicenza",
    name: "Shri Guru Ravidass Temple Vicenza",
    address: "Via Giuseppe Ravizza, 26, 36075 Montecchio Maggiore, Italy",
    telephone: [""],
    links: []
  },
  {
    country: "Italy",
    city: "Bergamo",
    name: "Shri Guru Ravidass Darbaar Bergamo",
    address: "Via Repubblica 24060 Castelli Calepio Bergamo, Italy",
    telephone: [""],
    links: []
  },
  {
    country: "New Zealand",
    city: "Hastings",
    name: "Shri Guru Ravidass Temple Hastings NZ",
    address: "193 Havelock North road, Hastings, New Zealand",
    telephone: [""],
    links: []
  },
  {
    country: "New Zealand",
    city: "Auckland",
    name: "SHRI GURU RAVIDAS SABHA (Inc)",
    address:
      "1998 Great South Road Bombay Hill, Pukekohe, AUCKLAND,  New Zealand",
    telephone: [""],
    links: []
  },
  {
    country: "Portgual",
    city: "Portugal",
    name: "Shri Guru Ravidass Sabha",
    address: "R. Maria 8, 1170-016 Lisbon, Portugal",
    telephone: [""],
    links: []
  },
  {
    country: "Scotland",
    city: "Glasgow",
    name: "Shri Guru Ravidass Sabha",
    address: "251 Nithdele Road, Pollockshield, Glasgow",
    telephone: [""],
    links: []
  },
  {
    country: "Spain",
    city: "Barcelona",
    name: "Shri Guru Ravidass Sabha",
    address: "Avinguda d'en Caritg, 135, 08917",
    telephone: [""],
    links: []
  },
  {
    country: "U.S.A.",
    city: "Yuba city",
    name: "Shri Guru Ravidass Sabha",
    address: "2269 Bouge Road Yuba City, California",
    telephone: [""],
    links: []
  },
  {
    country: "U.S.A.",
    city: "Seatle",
    name: "Shri Guru Ravidass Temple",
    address: "4823 S 138th Street, Tukwila, WA 98168",
    telephone: [""],
    links: []
  },
  {
    country: "U.S.A.",
    city: "New york",
    name: "Shri Guru Ravidass Temple",
    address: "6101 Broadway, Woodside, NY 11377",
    telephone: [""],
    links: []
  },
  {
    country: "U.S.A.",
    city: "Bay Area",
    name: "Shri Guru Ravidass Temple",
    address: "5785 Robertson Avenue, Newark, California",
    telephone: [""],
    links: []
  },
  {
    country: "U.S.A.",
    city: "Fresno",
    name: "Shri Guru Ravidass Temple",
    address: "5025 S Cherry Ave, Fresno, CA 93706",
    telephone: [""],
    links: []
  },
  {
    country: "U.S.A.",
    city: "Pittsburg",
    name: "Shri Guru Ravidass Temple",
    address: "Crestview Dr, Pittsburg, CA 94565",
    telephone: [""],
    links: []
  },
  {
    country: "U.S.A.",
    city: "Sacramento",
    name: "Shri Guru Ravidass Temple",
    address: "6221 16th St, Rio Linda, CA 95673",
    telephone: [""],
    links: []
  },
  {
    country: "U.S.A.",
    city: "Houston",
    name: "Shri Guru Ravidass Temple",
    address: "7902 Boone Road, Houston",
    telephone: [""],
    links: []
  },
  {
    country: "U.S.A.",
    city: "Selma",
    name: "Shri Guru Ravidass Temple",
    address: "2650 Baline Avenue, Selma, California",
    telephone: [""],
    links: []
  },
  {
    country: "United Kingdom",
    city: "Coventry",
    name: "Shri Guru Ravidass Temple",
    address: "19 Jesmond Rd, Coventry CV1 5AQ, UK",
    telephone: [""],
    links: []
  },
  {
    country: "United Kingdom",
    city: "Coventry",
    name: "Shri Guru Ravidass Temple (Foleshill)",
    address: "475 Foleshill Road, Foleshill, Coventry, England",
    telephone: [""],
    links: []
  },
  {
    country: "United Kingdom",
    city: "Darlaston",
    name: "Shri Guru Ravidass Temple Darlaston",
    address: "37 - 38 Pinford Street, Darlaston, WS10 8SY",
    telephone: [""],
    links: []
  },
  {
    country: "United Kingdom",
    city: "Bedford",
    name: "Sri Guru Ravidass Sabha - Bedford",
    address: "Guru Ravidass Lane, Bedford MK40 1ED",
    telephone: [""],
    links: []
  },
  {
    country: "United Kingdom",
    city: "Wolverhampton",
    name: "Shri Guru Ravidass Temple Wolverhampton",
    address: "181 Dudley Rd, Wolverhampton WV2 3RD, UK",
    telephone: [""],
    links: []
  },
  {
    country: "United Kingdom",
    city: "Willenhall",
    name: "Shri Guru Ravidass Gurdwara - Willenhall",
    address: "13 Somerford Place, WV13 3 Willenhall, England",
    telephone: [""],
    links: []
  },
  {
    country: "United Kingdom",
    city: "Walsall",
    name: "Guru Ravidass Welfare Association",
    address: "186 Stafford St, Walsall WS2 8ED, UK",
    telephone: [""],
    links: []
  },
  {
    country: "United Kingdom",
    city: "Southall",
    name: "Shri Guru Ravidass Gurudwara",
    address: "282 - Western Road UB2 5JT Southall, Slough, United Kingdom",
    telephone: [""],
    links: []
  },
  {
    country: "United Kingdom",
    city: "Southampton",
    name: "Shri Guru Ravidass Gurdwara Southampton UK",
    address: "130 Northumberland Road, Southampton",
    telephone: [""],
    links: []
  },
  {
    country: "United Kingdom",
    city: "Strood",
    name: "Shri Guru Ravidass Bhawan",
    address: "1 Vicarage Road, Strood, Rochester England, ME2 4DG",
    telephone: [""],
    links: []
  },
  {
    country: "United Kingdom",
    city: "Newham",
    name: "Shri Guru Ravidass Sabha Newham London",
    address: "28 Carlyle Rd, London E12 6BN, UK",
    telephone: [""],
    links: []
  },
  {
    country: "United Kingdom",
    city: "Luton",
    name: "Sri Guru Ravidass Bhawan Luton",
    address: "31A  Cardigan Street, Luton",
    telephone: [""],
    links: []
  },
  {
    country: "United Kingdom",
    city: "Lethworth",
    name: "Gurudwara Shri Guru Ravidass Sabha",
    address: "167 Glebe Road, Lethworth",
    telephone: [""],
    links: []
  },
  {
    country: "United Kingdom",
    city: "Leicester",
    name: "Shri Guru Ravidass Temple",
    address: "193 Harrison Road LE4 6 Leiscester, Leicester, United Kingdom",
    telephone: [""],
    links: []
  },
  {
    country: "United Kingdom",
    city: "Hitchin",
    name: "Shri Guru Ravidass Community Centre",
    address: "2 Knowlpiece, Hitchin, SG4 0, England",
    telephone: [""],
    links: []
  },
  {
    country: "United Kingdom",
    city: "Derby",
    name: "Shri Guru Ravidass Ji Gurudwara Derby",
    address: "Duncan Rd Derby DE23 8TS",
    telephone: [""],
    links: []
  },
  {
    country: "United Kingdom",
    city: "Bilston",
    name: "Shri Guru Ravidass Temple",
    address:
      "Sant Dera Baba Gobind Dass Temple(Ravidass) 95 Wellington Road Bilston",
    telephone: [""],
    links: []
  },
  {
    country: "United Kingdom",
    city: "Birmingham",
    name: "Shri Guru Ravidass Temple",
    address:
      "Shri Guru Ravidass Bhawan Birmingham, 1 Union Row Birmingham, United Kingdom",
    telephone: [""],
    links: []
  },
  {
    country: "United Kingdom",
    city: "Birmingham",
    name: "Shri Guru Ravidass Temple",
    address: "Gurudwara Ravidass Sabha, Union Row, Handsworth, Brimingham",
    telephone: [""],
    links: []
  },
  {
    country: "United Kingdom",
    city: "Bradford",
    name: "Gurudwara Shri Guru Ravidass Bhavan",
    address: "8 Breaton Street, Bradford",
    telephone: [""],
    links: []
  },
  {
    country: "United Kingdom",
    city: "Coventry",
    name: "Gurudwara Ravidass Sabha",
    address: "1, 19 Jesmond road Coventry",
    telephone: [""],
    links: []
  },
  {
    country: "United Kingdom",
    city: "Gravesend",
    name: "Gurudwara Ravidass Sabha",
    address: "Brandon Street, Gravesend, Kent,Gravesend, United Kingdom",
    telephone: [""],
    links: []
  },
  {
    country: "United Kingdom",
    city: "Derby",
    name: "Shri Guru Ravidass Temple",
    address: "Brunswick Street Derby DE23 8TP",
    telephone: [""],
    links: []
  },
  {
    country: "United Kingdom",
    city: "Berkshire",
    name: "Shri Guru Ravidass Temple",
    address: "79 Shaggy Calf Lane, Slough, Berkshire",
    telephone: [""],
    links: []
  }
];
