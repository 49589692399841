import React from "react";
import { templesData } from "../temples";
import Layout from "../components/Layout/Layout";
import { Paper } from "@material-ui/core";
import SEO from "../components/SEO";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    listStyleType: "none",
    margin: 0
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: "28px"
  },
  li: {
    marginBottom: 20
  },
  name: {
    color: theme.palette.primary.main,
    fontSize: "16px",
    marginBottom: "0"
  },
  hr: {
    margin: "20px 0"
  }
}));

const TemplesPage = () => {
  const classes = useStyles();

  return (
    <Layout>
      <SEO
        title="List of Ravidassia's temple in the World"
        description="List of Ravidassia's temple in the World"
      />
      <Paper
        style={{
          padding: "16px"
        }}
      >
        <h1 className={classes.title}>
          List of Ravidassia's temple in the World
        </h1>
        <ul className={classes.root}>
          {templesData.map((temple, i) => {
            return (
              <li key={i} className={classes.li}>
                <h4 className={classes.name}>
                  {temple.name} ({temple.city}, {temple.country})
                </h4>
                <span>{temple.address}</span>
                {i !== templesData.length - 1 && <hr className={classes.hr} />}
              </li>
            );
          })}
        </ul>
      </Paper>
    </Layout>
  );
};

export default TemplesPage;
